import real from "./../../images/real.webp";
import diploma from "./../../images/diploma.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import whatsapp from "./../../images/whatsapp.png";
import {
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./About.css";

const About = () => {
  return (
    <section className="about relative py-10">
      <div id="about" className="absolute -top-28"></div>
      <div className="about__container container mx-auto px-10">
        <div className="flex md:flex-row flex-col justify-center md:items-start items-center">
          <div className="md:w-1/2 w-full 2xl:px-32 xl:px-24 lg:px-10 px-0">
            <img
              loading="lazy"
              src={diploma}
              alt="פרסים בישראל"
              className="w-full h-full about__photo-diploma"
            />
          </div>
          <div className="md:w-1/2 w-full 2xl:px-44 xl:px-36 lg:px-20 md:px-9 px-0">
            <img className="about__photo md:block hidden" src={real} alt="איש עסקים" />
            <div className="about__contact flex h-max items-center justify-between">
              <div className="md:text-md text-sm font-bold text-regal-blue">
                <div className="contact-text">
                  <p className="font-bold">
                    אלכס טרטקובסקי
                  </p>
                </div>
                <div className="header__logo-socials about__tel-btn" >
                  <a href="tel: 0586557877" className="btn btn-1 btn-sep icon-info header__logo-tel">
                    <span className="header__logo-tel-txt">0586557877 </span>
                    <FontAwesomeIcon className="header__logo-tel-icon" icon={faPhone} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

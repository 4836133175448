import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactSlider from "react-slider";
import { setStep, setFloorRange } from "../../redux/quizReducer";
import "./../../index.css";
import styles from "./Quiz.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field } from "formik";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faArrowsLeftRight} from "@fortawesome/free-solid-svg-icons";
import henryGrin from "./../../images/henry-grin.webp";

const min = 0;
const max = 25;

const FifthStep = () => {
  const [values, setValues] = useState([min, max]);

  const dispatch = useDispatch();

  function validateNumber(value: string) {
    let error;
    if (!value) {
      error =
        "איזו קומה?";
    }
    return error;
  }

  const handleClick = () => {
    dispatch<any>(setFloorRange(values));
    dispatch<any>(setStep(7));
  };

  return (
    <div className="w-full relative h-full">      
      {/* <div className={styles.values}>
        <h4>עד</h4>
        <h4>מי</h4>
      </div>
      <ReactSlider
        className={"slider"}
        trackClassName="slider-track"
        invert
        min={min}
        max={max}
        value={values}
        onChange={setValues}
        step={1}
      /> */}

      <Formik
        initialValues={{
          minFloor: "",
          maxFloor:  "",
        }}
        onSubmit={(values: any) => {
          console.log(values);
          dispatch<any>(setFloorRange([values.minFloor, values.maxFloor]));
          dispatch<any>(setStep(7));
        }}
      >
        {({ errors, touched, isValidating }) => (
          <Form>
            <div className="flex flex-col">
              {/* <h1 className="md:text-lg text-md text-center mb-8">כמות החדרים</h1> */}
              <h1 className="md:text-lg text-md text-center mb-5">?איזה קומה</h1>

              <div className={styles.priceWrap}>
                <div className={styles.formPriceWrapper}>
                  <p className={`${styles.formPriceTitle} top-text`}>עד</p>
                  <Field
                    className={`${styles.formPrice} text-right`}
                    name="maxFloor"
                    validate={validateNumber}
                  />
                </div>
                <div className={styles.formPriceWrapper}>
                  <p className={`${styles.formPriceTitle} top-text`}>מי</p>
                  <Field
                    className={`${styles.formPrice} text-right`}
                    name="minFloor"
                    validate={validateNumber}
                  />
                </div>
              </div>
              <div className="text-regal-red md:text-lg text-sm text-center err-text _rtl">
              {(errors.maxFloor && touched.maxFloor && (
                //@ts-ignore
                <div>{errors.maxFloor}</div>
              )) ||
                (errors.minFloor && touched.minFloor && (
                //@ts-ignore
                  <div>{errors.minFloor}</div>
                ))}
            </div>
            </div>

            <div className={styles.buttonWrapper}>
              <button className={styles.button}>
                <FontAwesomeIcon className="mr-3" icon={faArrowLeft} />
                להמשיך
              </button>

              <button
                className={styles.backButton}
                onClick={() => {
                  dispatch<any>(setStep(5));
                }}
              >
                חזור
                <FontAwesomeIcon className="ml-3" icon={faArrowRight} />
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {/* <div className={styles.values}>
        <h4 className="quiz-arrows quiz_left md:text-lg text-md">
          <span className="quiz-arrows__span">{values[1]}</span>
          <FontAwesomeIcon className="quiz-arrows__arrow" icon={faArrowsLeftRight} />
        </h4>
        <h4 className="quiz-arrows quiz_right md:text-lg text-md">
          <span className="quiz-arrows__span">{values[0]}</span>
          <FontAwesomeIcon className="quiz-arrows__arrow" icon={faArrowsLeftRight} />
        </h4>
      </div> */}
      <div className="step-6-row step-6-img w-full flex 2xl:flex-col lg:flex-row md:flex-row flex-col items-center">
        <img
          className="md:w-60 md:h-60 w-36 h-36 -mt-7"
          src={henryGrin}
          alt="אייקוני אייפון"
          loading="lazy"
        />
        <p className="text-center md:text-lg text-md -mt-5">
          אכן שאלה פילוסופית. אם הקומה לא ממש חשובה לך, אפשר לדלג על השאלה,
          החברים שלי כבר יסתדרו
        </p>
      </div>
      {/* <div className={styles.buttonWrapper}>
        <button className={styles.button} onClick={handleClick}>
          <FontAwesomeIcon className="mr-3" icon={faArrowLeft} />
          להמשיך
        </button>

        <button
          className={styles.backButton}
          onClick={() => {
            dispatch<any>(setStep(5));
          }}
        >
          חזור
          <FontAwesomeIcon className="ml-3" icon={faArrowRight} />
        </button>
      </div> */}
    </div>
  );
};

export default FifthStep;

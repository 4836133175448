import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import styles from "./Feedback.module.css";
import image from "./../../images/properties.png";
import like from "./../../images/henry-like.png";
import { propertyTypeVariants } from "../../types/types";
import { useDispatch } from "react-redux";
import "./../../anims.css";
import {
  setAddition,
  setName,
  setNumber,
  setPropertyFor,
} from "../../redux/quizReducer";
import { useSelector } from "react-redux";
import emailjs from "@emailjs/browser";
import { RootState } from "../../redux/store";
import DataSended from "../quiz/DataSended";

import "./Update.css"

function requireValidate(value: string) {
  let error;
  if (!value) {
    error = "Required field";
  }
  return error;
}

const variants: Array<propertyTypeVariants> = [
  { var: "למגורים" },
  { var: "להשקעה" },
  { var: "להשקעה ומגורים" },
];

const Feedback = () => {
  const [isSended, setIsSended] = useState(false);
  const [showElement, setShowElement] = React.useState(true);
  const dispatch = useDispatch();

  const data = useSelector((state: RootState) => state.quiz);
  const message = `propertyFor: ${data.propertyFor}, name: ${data.name}, email: ${data.email}, addition: ${data.addition}`;

  
  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_15jhucg",
      "template_1kzd1ip",
      e.target,
      "Ztg4HCsqOzH2Nk60U"
    );
    setShowElement(false);
  };
  

  const action = () => {
    setIsSended(false);
  };

  return (
    <section className={styles.wrapper}>
      <div className="container relative mx-auto w-full px-10">
        <div id="feedback" className="absolute -top-36"></div>
        <h1 className="md:text-4xl text-3xl w-full text-center pt-10 text-regal-blue">
          איזה נדל"ן אתם מחפשים 
        </h1>
        <div className="flex xl:flex-row flex-col h-max md:pt-10 pt-5">
          <div className="2xl:w-2/5 xl:w-1/2 lg:w-2/3 mx-auto w-full sm:mb-10 mb-0 px-20">
            <img
              src={image}
              alt='эנדל " ן בסמארטפון'
              className="feedback__img w-full h-full"
            />
          </div>
          {isSended ? (
            showElement ? (
              <div
                id="send"
                className="flex flex-col justify-center items-center xl:w-1/2 w-full"
              >
                <img src={like} alt="like-henry" loading="lazy"></img>
                <p className="mb-5 md:text-2xl text-xl text-center text-regal-blue">
                  !תודה
                </p>
                <p className="mb-5 md:text-2xl text-xl text-center text-regal-red">
                  לחץ על הכפתור "שלח" והמומחה שלנו יצור איתך קשר בקרוב כדי להציג
                  בפניך את האפשרויות הטובות דירות
                </p>
                <form onSubmit={sendEmail}>
                  <textarea name="message" value={message} className="hidden" />
                  <button
                    type="submit"
                    className="border border-regal-blue w-max text-regal-blue px-8 py-4 flex items-center rounded-md duration-300 hover:text-white hover:bg-regal-blue hover:px-16"
                  >
                    שלח
                  </button>
                </form>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center xl:w-1/2 w-full">
                <DataSended quizType="null" action={action} />
              </div>
            )
          ) : (
            <div
              id="form"
              className="xl:w-1/2 w-full flex flex-col xl:text-left text-center items-center"
            >
              <p className="feedback-title w-full text-2xl sm:pt-0 pt-5">
                צור קשר! אנא השאר פרטים ונחזור אליך בקדם
              </p>
              <Formik
                initialValues={{
                  name: "",
                  number: "",
                  purpose: "",
                  addition: "",
                }}
                onSubmit={(values: any) => {
                  setIsSended(true);
                  setShowElement(true);
                  dispatch(setName(values.name));
                  dispatch(setNumber(values.number));
                  dispatch(setPropertyFor(values.purpose));
                  dispatch(setAddition(values.addition));
                }}
              >
                {({ errors, touched, isValidating }) => (
                  <Form className="w-full">
                    <div className={styles.formBlock}>
                      <div className={styles.inputWrapper}>
                        <Field
                          className={`${styles.input} text-right feedback__text-input`}
                          name="name"
                          validate={requireValidate}
                          placeholder="שם"
                        />
                      </div>
                      <div className={styles.error}>
                        {errors.name && touched.name && (
                          //@ts-ignore
                          <div>{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className={styles.formBlock}>
                      <div className={styles.inputWrapper}>
                        <Field
                          className={`${styles.input} text-right feedback__text-input`}
                          name="number"
                          validate={requireValidate}
                          placeholder="(מספר טלפון (וואטסאפ"
                        />
                      </div>
                      <div className={styles.error}>
                        {errors.number && touched.number && (
                          //@ts-ignore
                          <div>{errors.number}</div>
                        )}
                      </div>
                    </div>
                    <div className="pt-5">
                      <h1 className="feedback-title text-2xl pb-2">?לאיזו מטרה ישמש הנכס</h1>
                      <div className="feedback__checkbox-parent">
                        {variants.map((variant) => (
                          <div>
                            <div className="feedback__checkbox md:text-lg text-sm flex">
                              <Field
                                className="mr-5"
                                name="purpose"
                                type="checkbox"
                                value={variant.var}
                                validate={requireValidate}
                              />
                              <p className={styles.formCheckboxTitle}>
                                {variant.var}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      
                      <div className={styles.error}>
                        {errors.purpose && touched.purpose && (
                          //@ts-ignore
                          <div>{errors.purpose}</div>
                        )}
                      </div>
                    </div>
                    <div className={styles.formBlock}>
                      <h1 className="feedback-title w-full text-2xl mb-4">מידע נוסף</h1>
                      {/* <p className="text-right w-full mb-7 text-black">
                        {
                          
                        }
                      </p> */}
                      <div className={styles.inputBlock}>
                        <Field
                          className={`${styles.formArea} text-right feedback__text-input`}
                          name="addition"
                          component="textarea"
                          placeholder=" זה יסייע לנו לבנות את תיק ההצעות האישי האופטימלי (שטח, מחיר, אזור, כמות חדרים, העדפות אחרות) "
                        />
                      </div>
                      <div className={styles.error}>
                        {errors.addition && touched.addition && (
                          //@ts-ignore
                          <div>{errors.addition}</div>
                        )}
                      </div>
                    </div>
                    <div className={styles.buttonWrapper}>
                      <div className="text-right w-full">
                        <button
                          className="px-6 py-3 border border-regal-blue hover:px-12 duration-300 hover:bg-regal-blue hover:text-white text-regal-blue"
                          type="submit"
                        >
                          שליחה
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Feedback;

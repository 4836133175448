import React, {useEffect, useState} from "react";
import Carousel from "./components/carousel/Carousel";
import Feedback from "./components/feedback/Feedback";
import Hero from "./components/hero/Hero";
import Interlayer from "./components/interlayer/Interlayer";
import Question from "./components/question/Question";
import About from "./components/about/About";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./anims.css";
import logo1 from "./images/logo1.png";
import whatsapp from "./images/whatsapp.png";

function App() {
	const [scroll, setScroll] = React.useState(0);
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [height, setHeight] = useState<number>(window.innerHeight);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const isMobile = width / height < 1.45;

	const handleScroll = () => {
		setScroll(window.scrollY);
	};

	React.useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);
	return (
		<div className="relative">
			{isMobile ? (
				scroll > 200 ? (
					<div id="header">
						<Header />
					</div>
				) : (
					<div className="absolute top-5 right-5">
						<img className="w-20 h-full" src={logo1} alt="logo1"></img>
					</div>
				)
			) : (
				<Header />
			)}

			{scroll > 900 && (
				<a
					href="https://wa.me/972586557877?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82"
					id="arrowUp"
					className="px-4 py-4 rounded-full fixed right-5 bottom-5  cursor-pointer z-50"
				>
					{/* <FontAwesomeIcon className="text-white text-xl" icon={faAngleUp} /> */}
					<img
						loading="lazy"
						className="footer__social w-16 h-full"
						src={whatsapp}
						alt={"watsapp"}
					/>
				</a>
			)}
			<div>
				<Hero />
				<Interlayer />
				<Carousel />
				<Feedback />
				<About />
				<Question />
				<Footer />
			</div>
		</div>
	);
}

export default App;

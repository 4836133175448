import React, {useState, useEffect} from "react";
import "./Header.css";
import logo from "./../../images/logo.png";
import logo1 from "./../../images/logo1.png";
import whatsapp from "./../../images/whatsapp.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";

const navs = [
	{title: "צור קשר", anchor: "#about"},
	{title: "קבל ייעוץ", anchor: "#feedback"},
	{title: "חיפוש נכס", anchor: "#objects"},
	{title: "עמוד הבית", anchor: "#hero"},
];

// const socials: Array<SocialType> = [
//   {
//     alt: "facebook icon",
//     img: facebook,
//     link: "https://www.facebook.com/buyflathaifa",
//   },
//   {
//     alt: "instagram icon",
//     img: instagram,
//     link: "https://www.facebook.com/buyflathaifa",
//   },
//   {
//     alt: "whatsapp icon",
//     img: whatsapp,
//     link: "https://wa.me/972586557877?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82",
//   },
// ];

const Header = () => {
	const [scroll, setScroll] = React.useState(0);
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [height, setHeight] = useState<number>(window.innerHeight);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);

		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const isMobile = width / height < 1.45;
	console.log(width);

	const handleScroll = () => {
		setScroll(window.scrollY);
	};

	React.useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<header
			className={
				scroll < 200
					? menuOpen
						? "text-regal-blue z-40 fixed w-full flex justify-center duration-500 backdrop-blur-sm"
						: "text-regal-blue z-40 fixed w-full flex justify-center duration-500"
					: "text-regal-blue z-40 fixed w-full flex justify-center bg-white duration-500"
			}
		>
			<nav className="header__nav container relative mx-auto flex xl:flex-row flex-col items-start px-10">
				<div className="header__logo-wrap flex justify-between logo-wrap">
					<a href="/">
						<img
							className={
								!isMobile && width > 1279
									? "header-logo w-52 h-full"
									: "header-logo w-16 py-2 h-full"
							}
							src={!isMobile && width > 1279 ? logo : logo1}
							alt="לוגו של רימקס"
							loading="lazy"
						></img>
					</a>
					<div className={isMobile ? "menu1" : "menu"} onClick={() => setMenuOpen(!menuOpen)}>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<div
						className={
							// menuOpen
							// 	? "header__logo-socials _hidden _mob _logo-socials"
							"header__logo-socials _logo-socials header__logo-socials-reset-abs"
						}
					>
						<a href="tel: 0586557877" className="btn btn-1 btn-sep icon-info header__logo-tel">
							<span className="header__logo-tel-txt">0586557877 </span>
							<FontAwesomeIcon className="header__logo-tel-icon" icon={faPhone} />
						</a>
					</div>
					{/* <div
						className={
							menuOpen
								? "header__logo-socials _hidden _logo-socials"
								: "header__logo-socials _logo-socials"
						}
					>
						<a
							target="_blank"
							rel="noreferrer"
							className="header__logo-wa-link w-9 mx-3 duration-300 hover:-translate-y-1"
							href="https://wa.me/972586557877?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82"
						>
							<img
								className="header__logo-wa w-full h-full"
								loading="lazy"
								src={whatsapp}
								alt="whatsapp icon"
							/>
						</a>
						<a href="tel: 0586557877" className="btn btn-1 btn-sep icon-info header__logo-tel">
							<span className="header__logo-tel-txt">0586557877 </span>
							<FontAwesomeIcon className="header__logo-tel-icon" icon={faPhone} />
						</a>
					</div> */}
				</div>
				<ul className={menuOpen ? "open nav__wrapper" : "nav__wrapper"}>
					<div className="flex xl:flex-row flex-col items-center w-full justify-center">
						{navs.map((nav) => (
							<li className={scroll < 200 ? "drop-shadow-5xl xl:mb-0 mb-3" : "xl:mb-0 mb-3"}>
								<a
									href={nav.anchor}
									className={
										scroll < 200
											? "mx-3.5 font-bold px-2.5 py-1 2xl:text-2xl text-xl rounded-none uppercase cursor-pointer drop-shadow-5xl text-white hover:border-regal-red hover:border-b-2"
											: "mx-3.5 font-bold px-2.5 2xl:text-2xl text-xl py-1 rounded-none uppercase cursor-pointer text-regal-red hover:border-regal-red hover:border-b-2"
									}
								>
									{nav.title}
								</a>
							</li>
						))}
						<li
							className={
								scroll < 200 ? "nav__item drop-shadow-5xl xl:mb-0 mb-3" : "nav__item xl:mb-0 mb-3"
							}
						>
							<div className={!menuOpen ? "header__logo-socials" : "header__logo-socials _hidden"}>
								<a
									target="_blank"
									rel="noreferrer"
									className="header__logo-wa-link w-9 mx-3 duration-300 hover:-translate-y-1"
									href="https://wa.me/972586557877?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82"
								>
									<img
										className="header__logo-wa w-full h-full"
										loading="lazy"
										src={whatsapp}
										alt="whatsapp icon"
									/>
								</a>
								<a href="tel: 0586557877" className="btn btn-1 btn-sep icon-info header__logo-tel">
									<span className="header__logo-tel-txt">0586557877 </span>
									<FontAwesomeIcon className="header__logo-tel-icon" icon={faPhone} />
								</a>
							</div>
						</li>
					</div>
				</ul>
				<div
					className={
						!menuOpen
							? "header__logo-socials header__logo-socials-up"
							: "header__logo-socials _hidden header__logo-socials-up"
					}
				>
					<a
						target="_blank"
						rel="noreferrer"
						className="header__logo-wa-link w-9 mx-3 duration-300 hover:-translate-y-1"
						href="https://wa.me/972586557877?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82"
					>
						<img
							className="header__logo-wa w-full h-full"
							loading="lazy"
							src={whatsapp}
							alt="whatsapp icon"
						/>
					</a>
					<a href="tel: 0586557877" className="btn btn-1 btn-sep icon-info header__logo-tel">
						<span className="header__logo-tel-txt">0586557877 </span>
						<FontAwesomeIcon className="header__logo-tel-icon" icon={faPhone} />
					</a>
				</div>
			</nav>
		</header>
	);
};

export default Header;
